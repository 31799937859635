"use client";
import React from "react";
import Button from "@/components/ui/Button";
import SomethingWrongImg from "public/assets/something-wrong.svg";
import { HOME_PAGE } from "@/constants/routes.const";
import Link from "next/link";
import { LANGUAGES_CONTENT } from "@/constants/language";
import { StorageService } from "@/service/StorageService";

export default function NotFound() {
  const CONTENT =
    LANGUAGES_CONTENT[StorageService.getLanguage()]["dialog"].notfound;
  return (
    <div className="flex items-center justify-center h-screen ">
      <div className={"py-6 flex flex-col items-center justify-center gap-4"}>
        <SomethingWrongImg className="text-[300px]" />
        <h1
          className={"text-2xl font-bold leading-none text-center text-white"}
        >
          {CONTENT.title}
        </h1>
        <p className="text-sm text-center text-slate-400">
          {CONTENT.description}
        </p>
        <Button variant="solid" className={"py-6 px-10 text-lg mt-4"} asChild>
          <Link href={HOME_PAGE}>{CONTENT.cta}</Link>
        </Button>
      </div>
    </div>
  );
}
